<template>
  <!--      lab选项-->
  <ul class="lab-box">
    <li class="lab-icon" @click="open" v-if="props.activeTab === 2 || props.activeTab === 3">
      <img src="@/assets/images/home_more.svg">
    </li>
    <li
        class="label-item"
        v-for="(lab,i) in props.labelList"
        :key="lab.code"
        @click.stop="refresh(lab.id)"
    >
      {{lab.title || lab.folderName}}
    </li>
  </ul>
  <handelChannel :activeTab="props.activeTab" ref="handelChannelRef"/>
</template>

<script setup>
import axios from "axios";
import handelChannel from './handelChannel.vue'
import { ChannelApi } from "@/apis/api";
let channelApi = new ChannelApi();
const data = reactive({
  labels:[{name:'全部'},{name:'装修攻略'},{name:'灵感美集'}],
})
const props = defineProps({
  activeTab:{
    type:Number,
    default:()=>0
  },
  labelList:{
    type:Array,
    default:()=>[]
  },
  backChannelVal:{
    type:Function
  }
})
const emit = defineEmits(['backChannelVal'])
const handelChannelRef = ref(null)
const open = () => {
  handelChannelRef.value.openModal()
  handelChannelRef.value.getMyChannelList()
  handelChannelRef.value.getAllChannelList()
}
const refresh = (id) => {
  emit('backChannelVal',id)
}
</script>

<style lang="less" scoped>
.lab-box{
@apply flex items-center;
  li{
    margin-right: 12px;
    cursor: pointer;
  }
  .lab-icon{
    width: 36px;
    height: 26px;
  }
  .label-item{
    padding: 4px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 14px;
  }
}
</style>